
import { Component, Vue } from "vue-property-decorator";
import { post } from "@/api"
import { HcaptchaElement } from "@/interfaces/misc";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";

@Component({
  components: {
    VueHcaptcha
  }
})
export default class ForgotPassword extends Vue {
  email = "";
  resetLinkSend = false;
  validationError = false;
  validationInvoked = false;
  sitekey = process.env.VUE_APP_SITE_KEY;

  async sendEmailResetRequest(captcha_token: string) {
    try {
      const payload = {
        captcha_token: captcha_token,
        email: this.email
      }
      await post.passwordResetRequest(payload)
      this.resetLinkSend = true;
    } catch (error) {
      console.log(error)
    }
  }

  get isEmailValid(): boolean {
    const reg = new RegExp("\\S+@\\S+.\\S+");
    if (reg.test(this.email)) return true;
    return false;
  }

  validate() {
    if(!this.isEmailValid) {
      this.validationInvoked = true;
      return
    }
    (this.$refs.hcaptcha as HcaptchaElement).execute();
  }
}
